export const capitalizedWord = (word) => {
  const BRAND = {
    visa: "VISA",
    mastercard: "Master Card",
    american_express: "American Express",
    amex: "American Express",
    discover: "Discover",
  };

  if (BRAND[word]) {
    return BRAND[word];
  }
  const resultWithSymbol = word?.toLowerCase().replace(/[_-]/g, " ");
  const resultWithoutSymbol = word?.charAt(0)?.toUpperCase() + word?.slice(1);
  const lastResult = resultWithSymbol
    ? resultWithSymbol
        .split(" ")
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(" ")
    : resultWithoutSymbol;
  return lastResult || "";
};
