import Header from "src/components/Header/Header";
import {Order} from "src/components/Order/Order";

const Layout = () => {
   return (
     <>
       <Header />
       <main>
         <Order />
       </main>
     </>
   )
}
export default Layout