import axiosInstance from "./axios";

const getOrderData = async (businessId, orderId) => {
  try {
    return axiosInstance.get(`/orders/details/${businessId}/${orderId}`)
  }catch (e) {
    throw new Error(e.message)
  }
}

const order = {
  getOrderData,
}

export default order
