export const groupByCategory = (data) => {
  let sortIndex = 0;
  let groupName = null;
  return data?.reduce((acc, item) => {
    if (item?.externalId === '__INFO_LINE__') {
      groupName = item?.name;
    }
    if (item?.category) {
      let categoryId = item.category.name;
      if (groupName) {
        categoryId = `${groupName}__${categoryId}`;
      }
      if (!acc[categoryId]) {
        acc[categoryId] = {
          items: [],
          categoryName: item.category.name,
        };
      }
      acc[categoryId].items.push(item);
    } else {
      let ids = sortIndex;
      if (groupName) {
        ids = `${groupName}__${sortIndex}`;
      }
      if (!acc[ids]) {
        acc[ids] = {
          items: [],
        };
      }
      acc[ids].items.push(item);
      sortIndex++;
    }

    return acc;
  }, []);
};