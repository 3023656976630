import { memo } from "react";
const OrderHeader = ({ orderData }) => {
  return (
    <div id="receiptHeader">
      <div id="receiptHeaderInfo">
        <p className="companyName">{orderData?.business[0]?.title || orderData?.business[0]?.companyName}</p>
        <hr className="line-header" />
        <p className="user-message">
          {`Thanks for ordering, ${orderData?.contactInfo?.firstName || orderData?.contactInfo?.name}!`}
        </p>
        <p className="order-number">
          {`Order #${orderData.number}`}
        </p>
        <hr className="line-header-long" />
      </div>
    </div>
  );
};
export default memo(OrderHeader);
