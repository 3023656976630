import React, {memo} from "react";
import './notFound.css'
import Header from "src/components/Header/Header";
import NotFoundLogo from 'src/images/notFound.png';

const NotFound = memo(() => {
  return (
    <>
      <Header />
      <div className="main-found">
        <div className="not-found">
          <div className="not-found__content">
            <img src={NotFoundLogo} alt="not-found"/>
            <h3>Oops! Something went wrong.</h3>
          </div>
        </div>
      </div>
    </>

  )
})

export default NotFound