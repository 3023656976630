import React, { memo, useMemo } from "react";
import { valueFormat } from "src/helper/valueFormat";
import { checkDiscount } from "src/helper/checkDiscount";
import {
  convertKitchenTip,
  convertTaxAndFee,
  convertTips,
} from "src/helper/tips";
import { MESSAGE_VARIABLES } from "src/constants";

const OrderCharges = ({ orderData }) => {

  const checkTipSettings = useMemo(() => {
    const currentBusiness = orderData?.business[0];
    const { tipRestaurant, tipDriver } = currentBusiness?.settings?.website?.tipDisplaySetting;

    return {
      tipRestaurantEnabled: tipRestaurant?.showTipRestaurant,
      tipDriverEnabled: tipDriver?.showTipDriver
    };
  },[orderData])
  console.log(checkTipSettings, 'checkTipSettings');

  return (
    <>
      <tr className="total" style={{ fontSize: '16px' }}>
        <td>Subtotal</td>
        <td>
          {valueFormat(`$ ${Number(orderData?.charges?.subTotal).toFixed(2)}`)}
        </td>
      </tr>
      {orderData?.charges?.couponAmount && (
        <tr className="product">
          <td>Coupon amount</td>
          <td style={{ fontSize: '14px' }}>
            {valueFormat(
              `($ ${Number(orderData?.charges?.couponAmount).toFixed(2)})`
            )}
          </td>
        </tr>
      )}
      {orderData.type === "delivery" && (
        <tr className="product">
          <td>Delivery Fee</td>
          <td style={{ fontSize: '14px' }}>
            {valueFormat(
              `$ ${Number(orderData?.charges?.deliveryFee).toFixed(2)}`
            )}
          </td>
        </tr>
      )}
      {orderData?.charges?.discount && orderData?.charges?.discount > 0 ? (
        <tr className="product">
          <td>{MESSAGE_VARIABLES.cashback_used}</td>
          <td style={{ fontSize: '14px' }}>{checkDiscount(orderData?.charges?.discount)}</td>
        </tr>
      ) : (
        <></>
      )}
      {(checkTipSettings.tipDriverEnabled || checkTipSettings.tipRestaurantEnabled) && (
        <>
          {orderData.type !== "delivery" &&  !checkTipSettings.tipRestaurantEnabled ? (
            <></>
          ) : (
            <tr className="product">
              <td>Tips</td>
              <td style={{ fontSize: '14px' }}>{convertTips(orderData?.charges?.tipInfo, orderData)}</td>
            </tr>
          )}
          {(orderData.charges?.tipInfo?.kitchen ||
          orderData.charges?.tipInfo?.kitchen == 0) && checkTipSettings.tipRestaurantEnabled ? (
            <tr className="tip" style={{ paddingLeft: '10px' }}>
              <td>Kitchen</td>
              <td style={{ paddingRight: '70px', fontSize: '14px' }}>{convertKitchenTip(orderData.charges?.tipInfo?.kitchen)}</td>
            </tr>
          ) : (
            ""
          )}
          {orderData.type === "delivery" && checkTipSettings.tipDriverEnabled ? (
            <>
              {orderData.charges?.tipInfo?.driver ||
              orderData.charges?.tipInfo?.driver === 0 ? (
                <tr className="tip" style={{ paddingLeft: '10px' }}>
                  <td>Driver</td>
                  <td style={{ paddingRight: '70px', fontSize: '14px' }}>
                    {convertKitchenTip(orderData.charges?.tipInfo?.driver)}
                  </td>
                </tr>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
      <tr className="product">
        <td>Taxes and Fees</td>
        <td style={{ fontSize: '14px' }}>
          {convertTaxAndFee(
            orderData?.charges?.servicingFee,
            orderData?.charges?.tax
          )}
        </td>
      </tr>
      <tr className="product" style={{ paddingLeft: "10px" }}>
        <td>Tax</td>
        <td style={{ paddingRight: '70px', fontSize: '14px' }}>{valueFormat(`$ ${Number(orderData?.charges?.tax).toFixed(2)}`)}</td>
      </tr>
      {orderData?.charges?.servicingFee?.processingFee ||
      orderData?.charges?.servicingFee?.marketplaceFee ||
      orderData?.charges?.servicingFee?.ordersCoFee ? (
        <tr className="product" style={{ paddingLeft: "10px" }}>
          <td>Processing Fee</td>
            <td style={{ paddingRight: '70px', fontSize: '14px'  }}>{valueFormat(
                `$ ${Number(
                    orderData?.charges?.servicingFee?.processingFee + orderData?.charges?.servicingFee?.marketplaceFee + orderData?.charges?.servicingFee?.ordersCoFee
                ).toFixed(2)}`
            )}</td>
        </tr>
      ) : (
        ""
      )}
      <hr className="line-header" />
      <tr className="product-total">
        <td>Total</td>
        <td>
          {valueFormat(`$ ${Number(orderData?.charges?.total).toFixed(2)}`)}
        </td>
      </tr>
    </>
  );
};
export default memo(OrderCharges);
