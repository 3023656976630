import Layout from "src/layout/Layout";
import { Route, Routes } from 'react-router-dom';
import NotFound from "src/components/notFound/NotFound";
function App() {
  return (
    <div id='APP'>
    <Routes>
      <Route index element={<Layout />} />
      <Route path="/:businessId/:orderId" element={<Layout />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
    </div>
  );
}

export default App;